import { create } from "zustand";
import axios from 'axios';
import { store } from "../../../store";
import { persist } from 'zustand/middleware'

interface PointProcent {
  getPoints: () => void,
  data: any,
  deleteData: () => void,
  dataChamp: any,
}

interface Spec {
  dataSpec: any,
  getDataSpec: (itme: any) => void
  persist: any
}

export const useSpec = create<any>(persist((set) => ({
  dataSpec: [],
  dataCheck: [],
  getDataSpec: (item: any) => set((state: any) => (
    {
      dataSpec: [
        ...state.dataSpec,
        item
      ]
    }
  )),
  getCheck: (item: any) => set((state: any) => (
    {
      dataCheck: [
        ...state.dataCheck,
        item
      ]
    }
  )),
  deleteDataSpec: () => set(() => (
    {
      dataSpec: []
    }
  )),
  deleteDataCheck: () => set(() => (
    {
      dataCheck: []
    }
  ))
}),
  {
    name: 'storageSpec',
    getStorage: () => localStorage,
  }
))

export const usePointProcentStore = create<PointProcent>((set) => ({
  data: [],
  dataChamp: [],
  getChampionshipsAll:
    async () => {
      const jwtToken = store.getState().auth.jwtToken;
      await axios.post(
        'https://egubanov.online/neurodoc-go-test/neurodoc_go_test/getAllChampionship',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + jwtToken,
          },
        }
      )
        .then(res => {
          const data = res.data.all_championship
          set({ dataChamp: data })
        })
        .catch(err => console.log(err))
    },
  deleteData: () => set(() => (
    {
      data: []
    }
  )),
  getPoints: async () => {
    const jwtToken = store.getState().auth.jwtToken;
    await axios.post(
      'https://egubanov.online/neurodoc-go-test/neurodoc_go_test/getPoint',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + jwtToken,
        },
      }
    )
      .then(res => {
        const data = res.data.point
        set({ data: data })
      })
      .catch(err => console.log(err))
  }
}))